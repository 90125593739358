@font-face {
  font-family: "logo-light";
  src: url('../public/fonts/logo-light.woff') format('woff');
}

@font-face {
  font-family: "HaasGrotDisp-55Roman";
  src: url('../public/fonts/HaasGrotDisp-55Roman.woff') format('woff'),
  url('../public/fonts/HaasGrotDisp-55Roman.woff2') format('woff2');
}

@font-face {
  font-family: "NeueHaasGrotesk";
  src: url('../public/fonts/NHaasGroteskTXPro-65Md 2.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: "HaasGrotDisp-55Roman", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("../public/bottomaccent_blackbackground.png");
  background-position: bottom;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: black !important;
  color: white !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  width: 100%;
}

.btn-primary {
  background-color: #DF38A0;
  color: white;
  border-color: #DF38A0;
}

.nav-link {
  background-color: transparent !important;
  color: white !important;
}

.navbar-brand {
  background-color: transparent !important;
  color: white !important;
  font-family: "logo-light";
}

.table {
  background-color: black;
  color: white;
  table-layout: auto !important;
}

.page-link {
  background-color: black;
  color: white;
}

.page-item.active .page-link {
  background-color: #DF38A0;
  border-color: white;
}

.form-control {
  border-width: 5px;
  border-color: #DF38A0;
  border-radius: 1rem;
  background-color: transparent;
  color: white;
}

.logo-text {
  font-family: "logo-light", "Helvetica Neue", Helvetica, Arial, sans-serif;;
}

b {
  font-family: "NeueHaasGrotesk";
}

h1 {
  font-family: "NeueHaasGrotesk";
}